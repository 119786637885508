import React from 'react'
import { Col, Row, Cascader, Button } from 'antd'
import { Link } from 'react-router-dom'
import DebounceInput from '../../../components/DebounceInput'
import { CountryList } from './Location'

interface IAuditorTableOptionsProps {
  auditorName: string
  setAuditorName: (name: string) => void
  firmName: string
  setFirmName: (name: string) => void
  location: any
  setLocation: any
  handleReset: () => void
}

function AuditorTableOptions(props: IAuditorTableOptionsProps) {
  const { auditorName, setAuditorName, firmName, setFirmName, location, setLocation, handleReset } =
    props
  return (
    <Row justify="space-between">
      <Col xs={24} md={7}>
        <Cascader
          options={CountryList}
          value={location}
          onChange={setLocation}
          style={{ width: '100%' }}
          placeholder="Find by Location (case sensitive)"
          changeOnSelect
          showSearch
        />
      </Col>
      <Col xs={24} md={7}>
        <DebounceInput
          placeholder="Find by Firm"
          allowClear
          value={firmName}
          onChange={(e) => {
            setFirmName((e.target as HTMLInputElement).value)
          }}
        />
      </Col>
      <Col xs={24} md={7}>
        <DebounceInput
          placeholder="Find by Name"
          allowClear
          value={auditorName}
          onChange={(e) => {
            setAuditorName((e.target as HTMLInputElement).value)
          }}
        />
      </Col>
      <Col xs={24} md={2}>
        <Button onClick={handleReset} block>
          Reset
        </Button>
      </Col>
      <Col xs={24} style={{ marginTop: '10px' }}>

        {/* Uncomment the below option for add-auditor feature*/}
        {/* <Link to="/add-auditor">&gt; Unable to locate your auditor. Would you like to add one?</Link> */}

      </Col>
    </Row>
  )
}

export default AuditorTableOptions
