import React from 'react';
import { Typography, Row, Col, Collapse, Card, Space } from 'antd';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import faqList from './faqList.json';
import './FAQ.css';

const { Title, Paragraph } = Typography;

function FAQ() {
  return (
    <div className="faq-container">
      <Card className="faq-card" bordered={false}>
        <Row justify="center">
          <Col xs={24} lg={16}>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <Title level={2} className="faq-title">Frequently Asked Questions</Title>
              <Collapse 
                accordion 
                expandIconPosition="right" 
                className="faq-collapse"
                ghost
              >
                {faqList.faq.map((element, index) => (
                  <Collapse.Panel 
                    header={element.q} 
                    key={index} 
                    className="faq-panel"
                  >
                    <Paragraph className="faq-paragraph">
                      <ReactMarkdown>{element.a}</ReactMarkdown>
                    </Paragraph>
                  </Collapse.Panel>
                ))}
              </Collapse>
              <Paragraph className="faq-contact">
                If you have more questions, please <Link to="/info/contactus" className="faq-link">contact us</Link>. We cannot guarantee a response, but we will read every question and comment.
              </Paragraph>
            </Space>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default FAQ;
