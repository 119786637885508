import React, { Component, useCallback, useState } from 'react'
import { Alert, Button, Col, Form, Input, Row, Result, Card } from 'antd'
import { MailOutlined, MailTwoTone } from '@ant-design/icons'
import { user as userApi } from '@rap/api'

interface IForgetPasswordFormProps {
  isSubmitting: boolean
  handleForgotSubmit: (values: any, callback: () => void) => void
  error?: rateauditor.general.IErrorMessage
}

interface IForgotStepProps {
  isSuccess: boolean
  isSubmitting: boolean
  handleForgotSubmit: (values: any, callback: () => void) => void
  error?: rateauditor.general.IErrorMessage
}

const ForgetPasswordForm = (props: IForgetPasswordFormProps) => {
  const { handleForgotSubmit, isSubmitting, error } = props
  const [forgetForm] = Form.useForm()

  const onReset = () => {
    forgetForm.resetFields()
  }

  return (
    <Form
      onFinish={(values) => {
        handleForgotSubmit(values, onReset)
      }}
      form={forgetForm}
      initialValues={{
        email: '',
      }}
    >
      {error ? (
        <Form.Item>
          <Alert type="error" message={error.message} showIcon />
        </Form.Item>
      ) : (
        <></>
      )}
      <Form.Item
        name="email"
        rules={[
          { required: true, message: 'Please input your account email!' },
          { type: 'email', message: 'The input is not valid E-mail!' },
        ]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Enter your email address here"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isSubmitting} block>
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

const ForgotStep = (props: IForgotStepProps) => {
  const { isSuccess, isSubmitting, handleForgotSubmit, error } = props
  // check for error
  if (error) {
    return (
      <Result
        status="error"
        title="Error sending email"
        subTitle="We are experiencing technical difficulties. Please try again later."
        extra={
          <Button type="primary" href="/">
            Back Home
          </Button>
        }
      />
    )
  // check if form was submitted sucessfully
  } else if (!isSuccess) {
    return (
      <>
        <Row justify="center">
          <Col xs={22} md={12}>
            <h3>Enter your account email address and we will send you a password reset link.</h3>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={22} md={12}>
            <ForgetPasswordForm
              handleForgotSubmit={handleForgotSubmit}
              isSubmitting={isSubmitting}
              error={error}
            />
          </Col>
        </Row>
      </>
    )
  // password reset email was sent
  } else {
    return (
      <Result
        icon={<MailTwoTone />}
        status="success"
        title="Please check your mailbox"
        subTitle="We have send a password reset email to the email address."
        extra={
          <Button type="primary" href="/">
            Back Home
          </Button>
        }
      />
    )
  }
}

function ForgotPassword() {
  const [isSubmitting, setSubmitting] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const handleForgotSubmit = useCallback((data: { email: string }, resetForm: () => void) => {
    setSubmitting(true)
    userApi
      .postUserForgotPassword(data)
      .then(() => {
        setSubmitting(false)
        setSuccess(true)
        resetForm()
      })
      .catch((err) => {
        setSubmitting(false)
        setErrorMessage(err.response.data.error)
      })
  }, [])

  return (
    <Card className="cardContent" bordered={false}>
      <ForgotStep
        isSubmitting={isSubmitting}
        isSuccess={isSuccess}
        error={errorMessage}
        handleForgotSubmit={handleForgotSubmit}
      />
    </Card>
  )
}

export default ForgotPassword