import React, { useState, ChangeEvent, useEffect} from 'react';
import {Switch, Checkbox} from 'antd';
import './discussionForm.css'; // Import the CSS file
import { Dropdown, DropdownProps, Button, Form, Input, List, Container, Header} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { defaultDiscussions } from './defaultDiscussions';
import { selectLoginStatus, selectUserInfo } from '../../redux/userSlice';
import { useAppSelector } from '../../redux/hooks';
import ReactPaginate from 'react-paginate';
import { FaFlag, FaTrash} from 'react-icons/fa';
import Admin from '../admin/comment_verification';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { discussion as discussionAPI } from '@rap/api';

export interface Comment {
  _id: number;
  author: string;
  title: string;
  content: string;
  tags: string[];
  type: 'note' | 'discussion';
  replies: Comment[];
  admin: boolean;
  timestamp: string;
}

const getRandomAuthor = () => {
  const authors = [
    'Anonymous Helix',
    'Anonymous Mouse',
    'Anonymous Squirrel',
    'Anonymous Fox',
    'Anonymous Eagle',
    'Anonymous Wolf',
    'Anonymous Tiger'
  ];
  return authors[Math.floor(Math.random() * authors.length)];
};

export const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    // second: 'numeric'
  };

  const today = new Date();
  const isToday = date.toDateString() === today.toDateString();

  if (isToday) {
    return date.toLocaleString(undefined, options);
  }

  return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
};

const DiscussionForm: React.FC = () => {
  const [title, setTitle] = useState('');
  const [isCustomTitle, setIsCustomTitle] = useState(false);
  const [content, setContent] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [tagInput, setTagInput] = useState('');
  const [type, setType] = useState<'note' | 'discussion'>('discussion'); // Default to 'discussion'
  const [showInfo, setShowInfo] = useState(false); // State to control info text visibility
  const [comments, setComments] = useState<Comment[]>(); // Initialize with default discussions
  const [replyTo, setReplyTo] = useState<number | null>(null); // State to track which comment is being replied to
  const [replyContent, setReplyContent] = useState(''); // State to store reply content
  const [anonymous, setAnonymous] = useState(false); // State to track anonymous posting for replies
  const [replyAnonymous, setReplyAnonymous] = useState(false); // State to track anonymous posting for replies
  const [customTitle, setCustomTitle] = useState<string | undefined>();
  const [visibleReplies, setVisibleReplies] = useState<Set<number>>(new Set()); // Track visible replies
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [sort, setSort] = useState<'latest' | 'oldest'>('latest'); // Default to 'latest'
  const [currentPage, setCurrentPage] = useState<number>(0); // React Paginate uses 0-based indexing
  const commentsPerPage = 4; // Number of comments per page
  let isAdmin = false; // Set to true to enable admin features


  //analytics:
  //amdin analytics
  const [totalPosts, setTotalPosts] = useState(0);
  const [postsToday, setPostsToday] = useState(0);
  const [uniquePosts, setUniquePosts] = useState(new Set<string>());
  const [contributors, setContributors] = useState<Map<string, number>>(new Map());
  const [topContributors, setTopContributors] = useState<Array<{ author: string, count: number }>>([]);

  useEffect(() => {
    setTotalPosts(comments ? comments.length : 0);

    const today = new Date().toDateString();
    const postsTodayCount = comments ? comments.filter(comment => new Date(comment.timestamp).toDateString() === today).length : 0;
    setPostsToday(postsTodayCount);

    const uniqueTitles = new Set(comments ? comments.map(comment => comment.title) : '');
    setUniquePosts(uniqueTitles);

    discussionAPI.getDiscussions().then((response) => {
      const updatedDiscussions = response.data.discussion.map((discussion: any) => ({
        ...discussion,
        timestamp: discussion.created_at, // Map creation_time to timestamp for discussions
        replies: discussion.replies.map((reply: any) => ({
          ...reply,
          timestamp: reply.creation_time, // Map created_at to timestamp for replies
        })),
      }));

      setComments(updatedDiscussions);
    }).then(() => {
      setTotalPosts(comments ? comments.length : 0);
      console.log(comments);
    });
  }, []);

  // Handle change in search input
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const loginStatus = useAppSelector(selectLoginStatus)
  const userInfo = useAppSelector(selectUserInfo)

  //corerect the abiov ething belwo
  isAdmin = userInfo?.permission_group.find((group) => group === 'admin') ? true : false;

  const handleFlag = (id: number) => {
    alert('Thanks for flagging this comment. Our team will review it shortly.');
  }

  const handleDelete = (id: number) => {
    discussionAPI.deleteDiscussion(id.toString()).then((response) => {
      location.reload()
    });
  }

  const handleReplyFlag = (id: number) => {
    alert('Thanks for flagging this comment. Our team will review it shortly.');
  }

  const handleReplyDelete = (id: number) => {
    if (comments) {
      const parentComment = comments.find(comment => comment.replies.find(reply => reply._id === id));
      const parentId = parentComment?._id;
      if (parentId) {
        discussionAPI.deleteDiscussionReply(parentId.toString(), id.toString()).then((response) => {
          console.log('success')
          location.reload()
        })
      }
    }
  }


  // Filter comments based on selected tag and search term and sorting
  const filteredComments = comments ? comments
      .filter(comment =>
          (comment.content.toLowerCase().includes(searchTerm.toLowerCase()) || comment.title.toLowerCase().includes(searchTerm.toLowerCase())) &&
          (selectedTags.length > 0 ? selectedTags.some(tag => comment.tags.includes(tag)) : true)
      )
      .sort((a, b) => {
        // Convert timestamps to Date objects for comparison
        const dateA = new Date(a.timestamp);
        const dateB = new Date(b.timestamp);

        // Sort based on the sort state
        if (sort === 'latest') {
          return dateB.getTime() - dateA.getTime(); // Most recent first
        } else {
          return dateA.getTime() - dateB.getTime(); // Oldest first
        }
      }) : [];

  //user analytics:
  const userDiscussions = comments ? comments.filter(comment => comment.author === userInfo?.username && comment.type === 'discussion').length : 0;
  const userReplies = comments ? comments.reduce((count, comment) => {
    return count + comment.replies.filter(reply => reply.author === userInfo?.username).length;
  }, 0) : 0;

  const userScore = userDiscussions * 10 + userReplies * 5 / (totalPosts + 1);
  let verificationMessage = ''
  if (userScore < 33) {
    verificationMessage = 'Your discussion score is low. Please participate in more discussions.'
  } else if (userScore <= 45) {
    verificationMessage = 'Your discussion score is average. Keep participating in discussions.'
  } else if (userScore <= 60) {
    verificationMessage = 'Your discussion score is good. Keep participating in discussions.'
  } else if (userScore <= 75) {
    verificationMessage = 'Your discussion score is very good. Keep participating in discussions.'
  } else {
    verificationMessage = 'Your discussion score is high. Keep up the good work!'
  }
  // const offset = currentPage * commentsPerPage;
  // const paginatedComments = filteredComments.slice(offset, offset + commentsPerPage);

  const handleTitleChange = (e: DropdownProps) => {
    const value = e.value as string;
    if (value === 'other') {
      setCustomTitle('');
      setIsCustomTitle(true);
    }
    // setTitle(value);
  };

  const handleCustomTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomTitle(e.target.value);
    setTitle(e.target.value);
  };

  const getUniqueTags = (comments: Comment[]): string[] => {
    const allTags = comments.flatMap(comment => comment.tags);
    return Array.from(new Set(allTags)); // Remove duplicates
  };


  const getUniqueTitles = (comments: Comment[]): string[] => {
    const allTitles = comments.map(comment => comment.title);
    return Array.from(new Set(allTitles)); // Remove duplicates
  }
  const uniqueTitles = getUniqueTitles(comments ? comments : []);
  const titleOptions = uniqueTitles.map(title => ({ key: title, text: title, value: title }));
  titleOptions.push({ key: 'other', text: 'Add Custom Title', value: 'other' });
  titleOptions.reverse();

  const DropdownExampleSearchSelection = () => (
      <Dropdown
          placeholder='Select Title'
          fluid
          search
          selection
          options={titleOptions}
      />
  )

  const uniqueTags = getUniqueTags(comments ? comments : []);
  const tagOptions = uniqueTags.map(tag => ({ key: tag, text: tag, value: tag }));

  const handleTagChange = (e: DropdownProps, data: any) => {
    setSelectedTags(data.value as string[]);
  };

  const handleReplyClick = (commentId: number) => {
    setVisibleReplies(prev => {
      const newReplies = new Set(prev);
      if (newReplies.has(commentId)) {
        newReplies.delete(commentId);
      } else {
        newReplies.add(commentId);
      }
      return newReplies;
    });
  };

  const handleContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event.target.value);
  };

  const handleTagInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagInput(event.target.value);
  };

  const handleAddTag = () => {
    if (tagInput.trim() !== '') {
      setTags([...tags, tagInput.trim()]);
      setTagInput('');
    }
  };

  const updateTopContributors = () => {
    const sortedContributors = Array.from(contributors.entries())
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5)
        .map(([author, count]) => ({ author, count }));

    setTopContributors(sortedContributors);
  };

  const handleRemoveTag = (index: number) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value as 'note' | 'discussion');
  };

  const handleAnonymityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnonymous(event.target.value === 'anonymous');
  };

  const handleReplyAnonymityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReplyAnonymous(event.target.value === 'anonymous');
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsCustomTitle(false);

    const authorName =  anonymous ? getRandomAuthor() : userInfo?.username || 'anonymous'; // Replace 'Your Name' with the actual user's name in a real application

    const newComment: Comment = {
      _id: comments ? comments.length + 1 : 0, // Generate unique ID (replace with UUID or backend-generated ID in real application)
      author: authorName,
      admin: false,
      title,
      content,
      tags,
      type,
      replies: [],
      timestamp: formatDate(new Date()).toString(),
    };

    // Update total posts and unique posts
    setTotalPosts(prev => prev + 1);
    setUniquePosts(prev => new Set(prev.add(newComment.title)));

    // Update contributor count
    setContributors(prev => {
      const updatedContributors = new Map(prev);
      updatedContributors.set(authorName, (updatedContributors.get(authorName) || 0) + 1);
      return updatedContributors;
    });

    if (replyTo !== null) {
      const updatedComments = [...comments || []];
      //addReply(updatedComments, replyTo, newComment);
      //setComments(updatedComments);
      //setReplyTo(null); // Reset reply state after adding reply
      console.log('Replying to comment with ID:', replyTo);
      console.log('Reply content:', newComment);
      //discussionAPI.postReply( newComment, replyTo.toString());

    } else {
      const newPost = {
        title: title,
        content: content,
        tags: tags,
        type: type,
        anonymous: anonymous,
      }
      discussionAPI.postDiscussion(newPost).then((response) => {
        location.reload();
      });
      // location.reload();
      // setComments([...comments, newComment]);
    }

    updateTopContributors();

    // Reset form fields after submission
    setTitle('');
    setContent('');
    setTags([]);
    // Show info text after submission
    setShowInfo(true);
  };

  // Assuming filteredComments is your array of comments
  const offset = currentPage * commentsPerPage;
  const paginatedComments = filteredComments.slice(offset, offset + commentsPerPage);
  const pageCount = Math.ceil(filteredComments.length / commentsPerPage);

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const addReply = (commentsArray: Comment[], parentId: number, reply: Comment) => {
    const parentComment = commentsArray.find(comment => comment._id === parentId);
    if (parentComment) {
      parentComment.replies.push(reply);
    }
    console.log(parentId);
    console.log(reply);
    const Reply = {
      content: reply.content,
      anonymous: reply.author === 'true'
    }
    discussionAPI.postReply(Reply, parentId.toString()).then((response) => {
      location.reload();
    });
  };

  const handleCloseInfo = () => {
    setShowInfo(false);
  };

  const handleOpenInfo = () => {
    setShowInfo(true);
  };

  const handleReply = (commentId: number) => {
    setReplyTo(commentId);
  };

  const handleReplyContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReplyContent(event.target.value);
  };

  const handleReplySubmit = (parentId: number) => {
    const reply: Comment = {
      _id: comments? comments.length + 1 : 0, // Generate unique ID (replace with UUID or backend-generated ID in real application)
      author: replyAnonymous.toString(),
      admin: false,
      title: '',
      content: replyContent,
      tags: [],
      type: 'note',
      replies: [],
      timestamp: formatDate(new Date()).toString(),
    };

    console.log(replyContent)

    const updatedComments = [...comments || []];
    addReply(updatedComments, parentId, reply);
    setComments(updatedComments);
    setReplyContent('');
    setReplyTo(null);
  };

  const renderComments = (comments: Comment[]) => (
      <ul className="comments-list">
        {comments.map(comment => (
            <li key={comment._id} className={`comment ${comment.type}`}>
              <div className="comment-header">
                <div>{comment.type === 'note' ? (
                    <p className="comment-type">Note</p>
                ) : (
                    <p className="comment-type">Discussion</p>
                )}
                </div>
                <div className="author-flag">
                  <strong>{comment.author}</strong>
                  { comment.admin && <span className={"admin-badge"}>Admin</span> }
                </div>
                {comment.type === 'discussion' && loginStatus === 'success' && (
                    <div className="reply-section">
                      <button className="reply-button" onClick={() => handleReply(comment._id)}>
                        Reply
                      </button>
                    </div>
                )}
              </div>
              <p><strong>{comment.title}</strong></p>
              <p className="comment-content">{comment.content}</p>
              <div className="tags">
                {comment.tags.map((tag, index) => (
                    <span key={index} className="tag">{tag}</span>
                ))}
              </div>
              <div className="timestamp">{comment.timestamp}
                {/* {loginStatus === 'success' && <button className="flag-button" onClick={() => handleFlag(comment._id)}>
              <FaFlag />
            </button>} */}
                {isAdmin && <button className="delete-button" title="Delete" onClick={() => handleDelete(comment._id)}>
                  <FaTrash />
                </button>} </div> {/* Display timestamp */}

              {/* Displaying replies */}
              {comment.replies.length > 0 && comment.type === 'discussion' && (
                  <div className="replies-section">
                    <br></br>
                    <button
                        className="replies-button"
                        onClick={() => handleReplyClick(comment._id)}
                    >
                      {visibleReplies.has(comment._id) ? 'Hide Replies' : 'Show Replies'}
                    </button>
                    {visibleReplies.has(comment._id) && (
                        <ul className="replies-list">
                          {renderReplies(comment.replies)}
                          <br></br>
                        </ul>
                    )}
                  </div>
              )}



              {/* Reply input field */}
              {replyTo === comment._id && (
                  <div className="reply-form">
            <textarea
                value={replyContent}
                onChange={handleReplyContentChange}
                placeholder="Write your reply..."
                required
            />
                    <button className="submit-reply-button" onClick={() => handleReplySubmit(comment._id)}>
                      Submit Reply
                    </button>
                    <div className="radio-group">
                      <label>Post anonymously:</label>
                      <input
                          type="radio"
                          id={`reply-anonymous-no-${comment._id}`}
                          value="name"
                          checked={!replyAnonymous}
                          onChange={handleReplyAnonymityChange}
                      />
                      <label htmlFor={`reply-anonymous-no-${comment._id}`}>No</label>
                      <input
                          type="radio"
                          id={`reply-anonymous-yes-${comment._id}`}
                          value="anonymous"
                          checked={replyAnonymous}
                          onChange={handleReplyAnonymityChange}
                      />
                      <label htmlFor={`reply-anonymous-yes-${comment._id}`}>Yes</label>
                    </div>
                  </div>
              )}
            </li>
        ))}
      </ul>
  );

  const renderReplies = (replies: Comment[]) => (
      replies.map(reply => (
          <li key={reply._id} className="reply">
            <strong>{reply.author}</strong>
            { reply.admin && <span className={"admin-badge"}>Admin</span> }
            {/* loginStatus === 'success' && <button className="reply-flag-button" onClick={() => handleReplyFlag(reply._id)}>
              <FaFlag />
            </button> */}
            {isAdmin && <button className="reply-delete-button" title="Delete" onClick={() => handleReplyDelete(reply._id)}>
              <FaTrash />
            </button>}
            <p className="reply-content">{reply.content}</p>
            <div className="timestamp">{reply.timestamp}</div> {/* Display timestamp */}
          </li>
      ))
  );

  return (
      <div className="discussion-form">
        <h1>Discussion Forum</h1>
        <div className="analytics">
          <p>Welcome to the Discussion Page! Feel free to share any comments or questions about financial statement preparation and auditing issues. Remember to be kind and respectful of others. Happy Discussing!</p>
          {/* <h2 className="section-title">Analytics</h2> */}
          {/* <div className="analytics-container">
        <div className="summary-card">
          <div className="summary-item">
            <h3>Total Posts</h3>
            <p>{totalPosts}</p>
          </div>
          <div className="summary-item">
            <h3>Unique Posts</h3>
            <p>{uniquePosts.size}</p>
          </div>
          <div className="summary-item">
            <h3>Total Contributors</h3>
            <p>{contributors.size}</p>
          </div>
        </div>
        {/* <div className="top-contributors-card">
          <h3>Top 5 Contributors</h3>
          <ul className="contributor-list">
            {topContributors.map(contributor => (
              <li key={contributor.author} className="contributor-item">
                <span className="contributor-name">{contributor.author}</span>: <span className="contributor-count">{contributor.count} posts</span>
              </li>
            ))}
          </ul>
        </div> }
      </div> */}
          <div>
            {/* loginStatus === 'success' &&
      <div className="user-analytics">
        <div className="user-analytics-left">
          <div className="analytics-card">
            <h3>Number of your Discussions</h3>
            <p>{userDiscussions}</p>
          </div>
          <div className="analytics-card">
            <h3>Number of your Replies</h3>
            <p>{userReplies}</p>
          </div>
        </div>
        <div className="user-analytics-right">
          <div className="analytics-card">
            <h3>Discussion Score</h3>
            <div className='circularMeter'>
              <CircularProgressbar
                className="circular-progress"
                value={userScore}
                text={`${userScore.toFixed(1)}%`}
                maxValue={100}
                styles={buildStyles({
                  textSize: '20px',
                  pathColor: '#5cdbd3',
                  textColor: '#5cdbd3',
                })}
              />
            </div>
            <p>{verificationMessage}</p>
          </div>
        </div>
  </div> */}
          </div>
        </div>
        <br></br>
        {showInfo ? (
            <div>
              <button className="close-info-button" onClick={handleCloseInfo}>Close Form</button>
            </div>
        ) : (
            <div>
              {loginStatus === 'success' ? (
                  <button className="open-info-button" onClick={handleOpenInfo}>Ask a Question?</button>
              ) : (
                  <div>
                    <p className="warning-message">Please sign in to participate in the discussion forum.</p>
                  </div>
              )}
            </div>
        )}
        <form onSubmit={handleSubmit} className={showInfo ? '' : 'hidden'}>
          <div className="form-group">
            <p className="warning-message">Please select a title and read the instructions carefully. Ensure your
              question is unique and add relevant tags. Thank you!</p>

            {/*  <label htmlFor="title">Title:</label>
        <Dropdown
            placeholder='Select Title'
            fluid
            search
            selection
            options={titleOptions}
            onChange={(_, data) => handleTitleChange(data)}
          /> */}
            <Form.Field>
              <br></br>
              <label htmlFor="customTitle">Enter Title: </label>
              <Input
                  id="customTitle"
                  value={customTitle}
                  scrolling
                  onChange={handleCustomTitleChange}
                  required
              />
            </Form.Field>
            {/*isCustomTitle && (
            <Form.Field>
              <br></br>
              <label htmlFor="customTitle">Enter Custom Title:  </label>
              <Input
                id="customTitle"
                placeholder="Enter your title"
                value={customTitle}
                scrolling
                onChange={handleCustomTitleChange}
                required
              />
            </Form.Field>
          ) */}
          </div>
          <div className="form-group">
            <label htmlFor="content">Content: </label>
            <textarea
                id="content"
                value={content}
                placeholder='Enter your content...'
                onChange={handleContentChange}
                required
            />
          </div>

          <div className="form-group">
            <label>Type:</label>
            <div className='radio-group'>
              <input
                  type="radio"
                  id="note"
                  value="note"
                  checked={type === 'note'}
                  onChange={handleTypeChange}
                  className='radio-input'
              />
              <label htmlFor="note">Note</label>
              <input
                  type="radio"
                  id="discussion"
                  value="discussion"
                  checked={type === 'discussion'}
                  onChange={handleTypeChange}
                  className='radio-input'
              />
              <label htmlFor="discussion">Discussion</label>
            </div>
            {type === 'discussion' ? (
                <p className="warning-message">Discussion post allows other users to reply to your post</p>
            ) : (
                <p className="warning-message">Note post is a one-way communication post</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="tags">Tags:</label>
            <input
                type="text"
                id="tags"
                value={tagInput}
                onChange={handleTagInputChange}
            />
            <button type="button" className="tag-add-button" onClick={handleAddTag}>Add Tag</button>
            <div className="tags-container">
              {tags.map((tag, index) => (
                  <span key={index} className="tag">
                {tag}
                    <button className="remove-tag-button" onClick={() => handleRemoveTag(index)}></button>
              </span>
              ))}
            </div>
          </div>
          <div className="form-group">
            <label style={{marginRight: 8}}>Would You Like To Post Anonymously?</label>
            <Checkbox
                checked={anonymous}
                onChange={(e) => setAnonymous(e.target.checked)}
                style={{transform: 'scale(1.2)', marginLeft: 2}}
            />
          </div>
          {anonymous ? (
              <p className="warning-message">Posting annonymously allows you to post without revealing your identity.
                Please be respectful and considerate in your posts.</p>
          ) : (
              <p className="warning-message">Posting with your name allows other users to know who posted the content.
                Please be respectful and considerate in your posts.</p>
          )}
          <div className="info-container">
            <p className="info-text">
              Thank you for participating in our discussion forum. Please ensure that your titles and content are
              respectful and considerate. Avoid the use of inappropriate language. When submitting your discussion
              forms, remember that they are visible to all users. Kindly promote diversity and consider our younger
              audience when posting. Please respect each other and create a welcoming environment for everyone.
            </p>
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>

        {/* Display comments */}
        <div>
          <br></br>
          <h2>Recent Discussions:</h2>
          <div className="row-container">
            <Input
                icon='search'
                placeholder='Search for discussions...'
                value={searchTerm}
                onChange={handleSearchChange}
                fluid
                style={{marginBottom: '20px'}}
            />

            {/* Dropdown for tag selection */}
            <Dropdown
                placeholder='Select Tags'
                fluid
                multiple
                selection
                options={tagOptions}
                onChange={handleTagChange}
                value={selectedTags}
                className='tag-dropdown'
                clearable
                style={{ marginBottom: '20px'}} // Adjust width to prevent horizontal
                scrolling
            />

            <Dropdown
                placeholder='Sort by'
                fluid
                selection
                options={[
                  { key: 'latest', text: 'Latest', value: 'latest' },
                  { key: 'oldest', text: 'Oldest', value: 'oldest' }
                ]}
                value={sort}
                onChange={(e, { value }) => setSort(value as 'latest' | 'oldest')}
                style={{ marginBottom: '20px' }}
            />
          </div>

          <br></br>
          {/* Pagination above comments */}

          {paginatedComments.length > 0 ? (
              renderComments(paginatedComments) // Use your styled comments rendering function
          ) : (
              <p>No Discussions found</p>
          )}
          <div className="pagination-controls">
            <br></br>
            <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />

          </div>
        </div>
      </div>
  );
};

export default DiscussionForm;
