import React, { useMemo } from 'react'
import { BrowserRouter } from 'react-router-dom'
import './App.css'

import Main from './router/Main'

const getBaseName = () => {
  switch (window.location.hostname) {
    case 'preview.rateauditor.org':
      return '/main'
    default:
      return '/'
  }
}

const App = () => {
  const basename = useMemo(() => {
    return getBaseName()
  }, [])

  return (
    <BrowserRouter basename={basename}>
      <Main />
    </BrowserRouter>
  )
}

export default App
