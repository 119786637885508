import React, { useCallback } from 'react'
import { Input } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import './SearchSimple.css'

const SearchSimple = () => {
  const navigate = useNavigate()

  const handleSearch = useCallback((name: string) => {
    navigate(`/browse?auditorName=${name}`)
  }, [])

  return (
    <div className="bg-white shadow-md mb-6 p-6">
      <div className="mb-4 text-lg">
        <span className="border-b-4 border-emerald-600">Find an Auditor, Review an Auditor</span>
      </div>
      <div className="mb-4">
        <Input.Search
          placeholder="Find an auditor by name..."
          allowClear
          enterButton="Search"
          size="large"
          onSearch={handleSearch}
        />
      </div>
      <div>
        <Link to="/browse">&gt; More Search Option    </Link>

        {/* Uncomment the below option for add-auditor feature*/}
        {/* <Link to="/add-auditor">&gt; Add an auditor?</Link> */}
        
      </div>
    </div>
  )
}

export default SearchSimple
