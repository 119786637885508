import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, Spin, Row, Col } from 'antd';
import AuditorTable from './components/AuditorTable';
import { auditor as auditorApi } from '@rap/api';
import './style.css';

const Browse = () => {
  const [isLoading, setLoading] = useState(true);
  const [auditorList, setAuditorList] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    auditorApi.getVerifiedAuditors().then((res) => {
      setAuditorList(res.data.auditors)
      setLoading(false)
    })
  }, [])

  return (
    <div className="browse-container">
      <Row justify="center" align="middle">
        <Col xs={36} sm={30} md={25} lg={21}>
          <Card
            className="cardContent"
            bordered={false}
            style={{
              borderRadius: '10px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              padding: '20px',
              margin: '20px 0',
            }}
          >
            {isLoading ? (
              <div className="loading-container">
                <Spin size="large" />
              </div>
            ) : (
              <AuditorTable
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                isLoading={isLoading}
                auditorList={auditorList}
              />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Browse;
