import React, { useEffect, useState } from 'react'
import { Card, Col, Row, message } from 'antd'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { user as userApi, rate as rateApi } from '@rap/api'
import styles from './style.module.sass'
import { selectLoginStatus, selectUserInfo } from '../../../redux/userSlice'
import { NotFound } from '../../exception/NotFound'
import Loading from '../../components/Loading'
import ProfileHeader from './components/ProfileHeader'
import RateList from './components/RateList'
import StatCard from './components/StatCard'

const operationTabList = [
  {
    key: 'rates',
    tab: (
      <span>
        Rates
        {/* {' '}
                <span
                    style={{
                        fontSize: 14,
                    }}
                >
                    (9)
                </span> */}
      </span>
    ),
  },
]

const UserProfile = () => {
  const params = useParams()
  const loginStatus = useSelector(selectLoginStatus)
  const userInfo = useSelector(selectUserInfo)
  const [verifiedNum, setVerifiedNum] = useState(0)

  const [tabKey, setTabKey] = useState('rates')
  const [user, setUser] = useState<rateauditor.api.user.IUserInfo>()
  const [isLoading, setLoading] = useState(true)
  const [rates, setRates] = useState<rateauditor.api.rate.IRateInfo[]>([])

  useEffect(() => {
    if (params.username) {
      if (params.username === userInfo?.username) {
        userApi.getCurrentUserInfo().then((res) => {
          setUser(res.data.user)
          setLoading(false)
        })

        rateApi.getCurrentUserRates().then((res) => {
          setRates(res.data.rates)
          const verifiedRates = res.data.rates.filter((rate: rateauditor.api.rate.IRateInfo) => rate.status === 'verified')
          setVerifiedNum(verifiedRates.length)
        })

      } else {
        // Currently we only allow account owner view their own profile
        setLoading(false)
        // rateApi.getUserRates(params.username).then((res) => {
        //   setRates(res.data.rates)
        // })
      }
    } else {
      setLoading(false)
    }
  }, [params.username, loginStatus])

  const handleDeleteRate = (rateId: string) => {
    if (loginStatus === 'success' && userInfo) {
      rateApi.deleteRate(rateId).then((res) => {
        if (res.data.status === 'success') {
          message.success('Delete Successful!')
          setRates(rates.filter((value) => value._id !== rateId))
        } else {
          message.warning(res.data.error.message)
        }
      })
    }
  }

  const renderChildrenByTabKey = (tabValue: string) => {
    if (tabValue === 'rates') {
      return <RateList rates={rates} handleDeleteRate={handleDeleteRate} />
      // } else if (tabValue === 'forum') {
      //     return <ForumList />
    }

    return null
  }

  if (isLoading) {
    return <Loading />
  }
  if (!user) {
    return <NotFound />
  }
  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <ProfileHeader user={user} />
        </Col>
      </Row>
      <Row gutter={24}>
      <Col span={24}>
          <Card
            style={{ width: '100%' }}
            className={`cardContent ${styles.tabsCard}`}
            bordered={false}
            tabList={operationTabList}
            activeTabKey={tabKey}
            onTabChange={(_tabKey) => {
              setTabKey(_tabKey)
            }}
          >
            {renderChildrenByTabKey(tabKey)}
          </Card>
        {/* <Col xs={24} lg={7}>
          <StatCard verifiedNum={verifiedNum} />
        </Col> */}
      </Col>
      </Row>
    </>
  )
}

export default UserProfile
