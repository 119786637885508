import React, { useState } from 'react';
import { Card, Carousel, Divider, List, Rate } from 'antd';
import dayjs from '../../utils/dayjs';
import styles from '../style.module.css';
import { Link } from 'react-router-dom';

interface IHomeRateProps {
  recentRates: rateauditor.api.rate.IRateInfo[];
}

interface rateData {
  average: number;
  communication: number;
  comment: string;
  created_at: string;
  updated_at: string;
  _id: string;
  knowledge: number;
  quality: number;
  team: number;
  value: number;
  author: {
    username: string;
    permission_group: string[];
  };
  auditor: {
    _id: string;
    first_name: string;
    last_name: string;
    firm_issuing_state: string;
    firm_issuing_country: string;
    firm_issuing_city: string;
    firm_name: string;
  };
}

const transformToRateData = (rate: any): rateData => ({
  average: rate.average,
  communication: rate.communication,
  comment: rate.comment,
  created_at: rate.created_at,
  updated_at: rate.updated_at,
  _id: rate._id,
  knowledge: rate.knowledge,
  quality: rate.quality,
  team: rate.team,
  value: rate.value,
  author: {
    username: rate.author.username,
    permission_group: rate.author.permission_group,
  },
  auditor: {
    _id: rate.auditor._id,
    first_name: rate.auditor.first_name,
    last_name: rate.auditor.last_name,
    firm_issuing_state: rate.auditor.firm_issuing_state,
    firm_issuing_country: rate.auditor.firm_issuing_country,
    firm_issuing_city: rate.auditor.firm_issuing_city,
    firm_name: rate.auditor.firm_name,
  },
});

const HomeRate = (props: IHomeRateProps) => {
  const { recentRates } = props;
  const [currentRate, setCurrentRate] = useState(0);

  const transformedRates = recentRates.map(rate => transformToRateData(rate));

  const handleBeforeChange = (nextRate: number) => {
    setCurrentRate(nextRate);
  };

  return (
    <div className="bg-white shadow-md mb-6 p-6">
          <div className="mb-4 text-lg">
              <span className="border-b-4 border-emerald-600">Example Comment</span>
          </div>
      <div>
        <List.Item.Meta
          title={
            <div className="flex items-baseline">
              <Rate disabled allowHalf value={5} />
              <span className="pl-3">
                Auditor: <Link to="/" className="pl-1">Mr(s). Smith</Link>
              </span>
            </div>
          }
          description={
            <div>
              <div className={styles.rateDetail}>
                <div className="flex justify-between">
                  <div>Team: {5}</div>
                  <div>Value: {5}</div>
                  <div>Knowledge: {5}</div>
                  <div>Communication: {5}</div>
                  <div>Quality: {5}</div>
                </div>
              </div>
              <br />
              <p style={{ color: 'black' }}>
                Mr(s). Smith has extensive expertise in auditing this industry. They have led an efficient and cooperative team.
                They planned the audit well and had excellent communication skills. In addition to timely communication regarding
                our financial statements, they have provided additional value by making suggestions to improve our reporting process.
              </p>
            </div>
          }
        />
      </div>
      <Divider />
      <div className="mb-4 text-lg">
              <span className="border-b-4 border-emerald-600">Recent Comments</span>
          </div>
      <div>
        <Carousel dotPosition="right" autoplay autoplaySpeed={7000} speed={1500} beforeChange={handleBeforeChange}>
          {transformedRates.map(rate => (
            <Card className={`${styles.rateCard} rounded-lg`} key={rate._id} bordered={false}>
              <List.Item.Meta
                title={
                  <div className="flex items-baseline">
                    <Rate disabled allowHalf value={rate.average} />
                    <span className="pl-3">
                      Auditor: <Link className="pl-1" to={rate.auditor ? `/auditor/${rate.auditor._id}` : '/'}>{rate.auditor ? `${rate.auditor.first_name} ${rate.auditor.last_name}` : 'no auditor found'}</Link>
                    </span>
                  </div>
                }
                description={
                  <div>
                    <div className={styles.rateDetail}>
                      <div className="flex justify-between">
                        <div>Team: {rate.team}</div>
                        <div>Value: {rate.value}</div>
                        <div>Knowledge: {rate.knowledge}</div>
                        <div>Communication: {rate.communication}</div>
                        <div>Quality: {rate.quality}</div>
                      </div>
                    </div>
                    <p style={{ color: 'black' }}>{rate.comment}</p>
                    <br></br>
                    <p className={styles.rateDate}>{dayjs(rate.created_at).fromNow()} {rate.author ? `by ${rate.author.username}` : ''}</p>
                  </div>
                }
              />
            </Card>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default HomeRate;
